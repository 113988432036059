import '../public/css/normalize.css'
import '../public/css/global.css'
import "../public/css/rc-trigger.css";
import "../public/css/slick.css";
import "../public/css/slick-theme.css";
import '../public/css/rc-pagination.css'

export default function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />
}
